<template>
    <v-row no-gutters class="mx-n1">
        <v-col cols="12" sm="6" class="pa-1">
            <v-text-field hide-details solo outlined :rules="rules" :flat="flat" :dense="dense" type="number" v-model.number="periodo_count" :min="0" />
        </v-col>
        <v-col cols="12" sm="6" class="pa-1">
            <v-select hide-details solo outlined :rules="rules" :flat="flat" :dense="dense" v-model="modif" :items="list_modifs" />
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: ['flat', 'dense', 'value', 'rules'],
    data: () => ({
        modif: 1,
        list_modifs: [
            { value: 1, text: "días" },
            { value: 7, text: "semanas" },
            { value: 30, text: "meses" },
            { value: 365, text: "años" }
        ]
    }),
    computed: {
        periodo_count: {
            get() {
                return Math.round(this.value / (60 * 60 * 24 * this.modif));
            },
            set(value) {
                this.$emit('input', value * (60 * 60 * 24) * this.modif);
            }
        }
    }
}
</script>