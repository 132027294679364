<template>
    <v-row>
        <v-col cols="12" md="6">
            <DashCard title="Información General" title-icon="assignment">
                <v-form id="form" ref="form">
                    <v-row>
                        <v-col cols="12">
                            <v-label>Nombre/Título</v-label>
                            <v-text-field hide-details solo outlined dense flat placeholder="Titulo" v-model="item.nombre" :rules="[rules.required]" />
                        </v-col>
                        <v-col cols="12">
                            <v-label>Descripción</v-label>
                            <v-textarea hide-details solo outlined dense flat placeholder="Descripción breve" v-model="item.descripcion" counter="500" :rules="[rules.required]" />
                        </v-col>
                    </v-row>
                </v-form>
            </DashCard>
        </v-col>
        <v-col cols="12" md="6">
            <DashCard title="Atributos" title-icon="edit">
                <v-form id="form" ref="form">
                    <v-row>
                        <v-col cols="12">
                            <v-label>Temáticas</v-label>
                            <CategoriasEditor v-model="item.categorias" :items="categorias" />
                        </v-col>
                        <v-col cols="12" v-if="$parent.$parent.isin('supers')">
                            <v-label>Autor</v-label>
                            <v-autocomplete clearable solo outlined flat dense 
                                prepend-inner-icon="account_circle" label="Seleccione un evaluador/autor"
                                v-model="item.autor" :items="evaluadores"
                                :item-text="(i) => i.first_name + ' ' + i.last_name" 
                                return-object :rules="[rules.required]"
                                hide-details />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-label>Empresa</v-label>
                            <v-autocomplete hide-details solo outlined flat dense label="Empresa"
                                v-model="item.empresa" :items="empresas"
                                item-text="nombre"
                                item-value="id"
                                return-object :rules="[rules.required]" />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-label>Gerencia</v-label>
                            <v-autocomplete hide-details solo outlined flat dense label="Gerencia"
                                v-model="item.gerencia" :items="gerencias"
                                item-text="nombre"
                                item-value="id"
                                return-object :rules="[rules.required]" />
                        </v-col>
                    </v-row>
                </v-form>
            </DashCard>
        </v-col>
        <v-col cols="12" md="6">
            <DashCard title="Validez" title-icon="schedule">
                <v-row>
                    <v-col cols="12">
                        <v-checkbox class="py-0 ma-0" hide-details v-model="validez_enabled" label="Este curso posee un tiempo limitado de validez" @change="validez_changed" />
                        <div class="mt-2" v-if="!!validez_enabled">
                            <v-label>Vencimiento de la validez del curso</v-label>
                            <DatePickerText dense flat v-model="item.validez_curso" />
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <v-label>Duración del certificado que se generará</v-label>
                        <PeriodoText dense flat v-model="item.validez_certificado" />
                    </v-col>
                </v-row>
            </DashCard>
        </v-col>
        <v-col cols="12">
            <DashCard body-class="text-right">
                <v-btn depressed color="primary" class="subtitle-2" large @click="upload">
                    Guardar cambios
                </v-btn>
                <v-btn depressed color="primary" class="subtitle-2 ml-2" large :to="back_route" exact>
                    Descartar
                </v-btn>
            </DashCard>
        </v-col>
        <Message ref="success" @onClose="$router.push(back_route)" />
        <Message ref="error" />
    </v-row>
</template>

<script>
import Message from '@/shared/components/Message'
import DashCard from '@/shared/components/DashCard'
import DatePickerText from '@/shared/components/DatePickerText'
import PeriodoText from '@/shared/components/PeriodoText'
import CategoriasEditor from '@/educap/components/CategoriasEditor'
import { axiosHelper, messages } from '@/helpers'
import { valid_required } from '@/helpers/validate'

export default {
    components: {
        DashCard,
        Message,
        PeriodoText,
        DatePickerText,
        CategoriasEditor
    },
    data: () => ({
        rules: {
            required: valid_required
        },
        item: {
            nombre: "",
            descripcion: "",
            categorias: [],
            autor: null,
            empresa: null,
            gerencia: null
        },
        empresas: [],
        gerencias: [],
        evaluadores: [],
        categorias: [],
        validez_enabled: false
    }),
    computed: {
        back_route() {
            const edit = this.$route.meta.edit_mode;
            var route = {};

            if(edit) {
                route = { 
                    name: 'dashboard_cap_item', 
                    params: {
                        id: this.item.id
                    }
                };
            } else {
                route = {
                    name: 'dashboard_cap_list' 
                };
            }
            return route;
        }
    },
    mounted() {
        let title = "Crear/editar curso de capacitación";
        const edit = this.$route.meta.edit_mode;

        axiosHelper.get('edu/cursos/editor/')
                .then(response => {
                    this.empresas = response.data.empresas;
                    this.gerencias = response.data.gerencias;
                    this.categorias = response.data.categorias;
                    this.evaluadores = response.data.evaluadores;
                });
        
        if(!edit) {
            title = "Crear curso de capacitación";
        } else {
            axiosHelper.get('edu/cursos/' + this.$route.params.id + '/?full')
                    .then(response => {
                        this.item = response.data;
                        this.validez_enabled = !!response.data.validez_curso;
                    });
            title = "Editar curso";
        }

        this.$parent.$parent.set_title(title, "edit");
    },
    methods: {
        validez_changed() {
            if(!this.validez_enabled)
                this.item.validez_curso = null;
        },
        upload() {
            const edit = this.$route.meta.edit_mode;
            let item = { ...this.item };

            if(!this.$refs.form.validate()) {
                this.$refs.error.show(messages.titles["error_generico"], messages.texts["error_check_fields"], "error");
                return;
            }
            
            if(!edit) {        
                axiosHelper.post('edu/cursos/', item)
                        .then(this.success)
                        .catch(this.error);
            } else {
                axiosHelper.put('edu/cursos/' + this.$route.params.id + '/', this.item)
                        .then(this.success)
                        .catch(this.error);
            }
        },
        success(response) {
            const edit = this.$route.meta.edit_mode;
            this.$refs.success.show(messages.titles['success_generico'], messages.texts[edit ? 'success_upload_edit' : 'success_upload_new'], "success");
        },
        error(response) {
            this.$refs.error.show(messages.titles['error_generico'], messages.texts['error_desconocido'], "error");
        }
    }
}
</script>