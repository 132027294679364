<template>
    <v-combobox v-model="value_list" :items="items" chips clearable label="Seleccione o escriba un tema breve" multiple solo outlined flat item-text="nombre" item-value="id" dense hide-details>
        <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip v-bind="attrs" :input-value="selected" close @click="select" @click:close="remove(item)">
                {{ item }}
            </v-chip>
        </template>
    </v-combobox>
</template>

<script>
export default {
    props: ['value', 'items'],
    computed: {
        value_list: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        remove (item) {
            this.value_list.splice(this.value.indexOf(item), 1)
            this.value_list = [...this.value_list]
        }
    }
}
</script>